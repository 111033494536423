import { useSearchGradingInstructionsQuery } from 'api/instruction'
import { ActionLocationType, TableView } from 'components/tableview'
import { ColumnType } from 'components/tableview/Filter'
import { usePaginatedData } from 'components/tableview/usePaginatedData'
import { useTableState } from 'components/tableview/useTableState'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import type { GradingInstruction, QueryResult } from 'types'

export const GradingInstructionOverview = () => {
  const tableState = useTableState()
  const data = usePaginatedData<QueryResult<GradingInstruction>>(useSearchGradingInstructionsQuery, tableState.pagination, tableState.columnFilters, tableState.sorting)
  const navigate = useNavigate()
  const FILTER_INPUT_DELAY_MS = 500

  const columns = useMemo(() => [
    { id: 'productCode', header: 'Product Code', type: ColumnType.TEXT, columnWidth: 4 },
    { id: 'sizeCode', header: 'SizeCode', type: ColumnType.TEXT, columnWidth: 4 },
    { id: 'label', header: 'Description', type: ColumnType.TEXT, columnWidth: 12 },
    { id: 'acceptableGrades', header: 'Available grades', type: ColumnType.TEXT, columnWidth: 8, enableColumnFilter: false, enableSorting: false }
  ], [])

  return (
    <TableView
      columns={columns}
      data={data?.results ?? []}
      rowCount={data?.totalCount ?? 0}
      textFilterDelay={FILTER_INPUT_DELAY_MS}
      tableState={tableState}
      actions={[
        {
          label: 'Edit',
          icon: 'Edit',
          columnWidth: 4,
          onClick: (row: GradingInstruction) => { navigate(`/instructions/grading/${row.id}`) }
        }
      ]}
      actionLocation={ActionLocationType.LAST}
      actionsWidth={48}
    />
  )
}
