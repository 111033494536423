import { mainAdminSplitApi } from 'redux/store/mainSplitApi'
import { type GradingInstruction, type QueryParameters, type QueryResult } from 'types'

const instructionsApi = mainAdminSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // Partial allows the query parameters to be optional, in which case they
    // are omitted from the request URI (which the backend knows how to handle)
    searchGradingInstructions: builder.query<QueryResult<GradingInstruction>, Partial<QueryParameters<GradingInstruction>>>({
      query: (parameters) => {
        return {
          url: '/instructions/grading',
          params: parameters
        }
      }
    })
  })
})

export const { useSearchGradingInstructionsQuery } = instructionsApi
